<template>
    <div class="form-controls" v-show="show">
        <div class="form-control-list">
            <div class="form-control-item" v-for="(c, i) in list" :key="i" @click.stop="select(c)">
                <div class="control-title">{{c.title}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {formItems} from "../common/constant/formItems";

    export default {
        name: "form-controls",
        data() {
            return {
                show: false,
                list: formItems
            }
        },
        mounted() {
            document.addEventListener("click", this.hide, false);
        },
        beforeDestroy() {
            document.removeEventListener("click", this.hide, false);
        },
        methods: {
            showControls() {
                this.show = true;
            },
            select(item) {
                this.$emit("select", item);
                this.hide();
            },
            hide() {
                this.show = false;
            }
        }
    }
</script>

<style lang="less">
    .form-controls {
        padding: 10px 30px;
        box-shadow: @box-shadow-base;
        background-color: @component-background;
        border: var(--border);
    }
    .form-control-list {
        margin: 20px -10px 0;
        width: 360px;
    }
    .form-control-item {
        display: inline-block;
        position: relative;
        margin: 0 10px 20px 10px;
        width: 70px;
        height: 58px;
        border: var(--border);
        cursor: pointer;
        overflow: hidden;
        text-align: center;
        line-height: 58px;
        &:hover {
            border-color: @primary-color;
            box-shadow: 0 0 6px #aed1f3;
        }
    }
    .control-icon {
        margin: 6px 0 4px;
        font-size: 24px;
        color: @text-color-secondary;
    }
</style>
