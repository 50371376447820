<template>
    <div class="album-detail" v-if="detail">
        <div class="flex-box align-center justify-between">
            <h3 class="title-txt">{{detail.name}}</h3>
            <a-button type="primary" @click="editDetail" v-show="tab == 1">编辑</a-button>
        </div>
        <a-tabs default-active-key="1" v-if="detail" @change="key => tab = key">
            <a-tab-pane key="1" tab="基础信息">
                <a-descriptions layout="vertical" bordered v-if="detail">
                    <a-descriptions-item label="集数">{{detail.count}}</a-descriptions-item>
                    <a-descriptions-item label="专辑状态">{{detail.status == 0 ? '更新中' : '已完结'}}</a-descriptions-item>
                    <a-descriptions-item label="排序">{{detail.order}}</a-descriptions-item>
                    <a-descriptions-item label="专辑简介" :span="3">{{detail.info}}</a-descriptions-item>
                    <a-descriptions-item label="关联老师" :span="3">
                        <div class="flex-box align-center album-teacher" @click="showTeacherDetail(detail.teacher)" v-if="detail.teacher">
                            <span style="margin-right: 12px">{{detail.teacher.name}}</span>
                            <img :src="detail.teacher.img" class="avatar mini" />
                        </div>
                        <span v-else>无</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="专辑封面" :span="3">
                        <img :src="detail.img" class="avatar">
                    </a-descriptions-item>
                    <a-descriptions-item label="详细介绍" :span="3">
                        <div v-html="detail.detail || '无'"></div>
                    </a-descriptions-item>
                </a-descriptions>
            </a-tab-pane>
            <a-tab-pane key="2" tab="音频管理">
                <audio-list :height="height" :album="detail" class="album-audio-list"></audio-list>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
    import AudioList from "../../layouts/AudioList";
    import {goToDetailRoute} from "../../common/js/tool";

    export default {
        name: "AlbumDetail",
        components: {
            AudioList
        },
        props: {
            detail: Object
        },
        data() {
            return {
                tab: 1,
                height: null
            }
        },
        mounted() {
            // 计算内容区域高度
            this.height = document.querySelector("#entity_detail").clientHeight - 60 - 32 - 32;
        },
        methods: {
            editDetail() {
                this.$emit("edit")
            },
            showTeacherDetail(item) {
                goToDetailRoute(this, {item, type: 'teacher'});
            }

        }
    }
</script>

<style scoped lang="less">
    .title-txt {
        font-weight: bold;
        font-size: 16px;
        color: @heading-color;
    }
    .album-teacher {
        padding: @padding-xs;
        cursor: pointer;
        &:hover {
            background-color: @background-color-base;
        }
    }
</style>
