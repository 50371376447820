<template>
    <data-list
            ref="dataList"
            url="/audio"
            name="音频"
            add-btn="添加音频"
            :query="query"
            :height="height"
            :columns="columns"
            :search-key-type="{name: true}"
            @add="addAudio"
            @edit="showEdit"
            @rowClick="showDetail">
        <template #title>
            <span>音频列表</span>
        </template>
        <template v-slot:query="{form}">
            <a-form-model-item label="">
                <a-input v-model="form.name" placeholder="输入音频名称查询"/>
            </a-form-model-item>
            <a-form-model-item label="" v-show="!album">
                <a-input v-model="form.name" placeholder="输入专辑名称查询"/>
            </a-form-model-item>
        </template>
    </data-list>
</template>

<script>
    import {goToDetailRoute} from "../common/js/tool";

    export default {
        name: "AudioList",
        props: {
            album: Object, //所属专辑
            height: Number
        },
        data() {
            return {
                columns: [
                    {title: '音频名称', dataIndex: 'name'},
                    {
                        title: '音频封面',
                        dataIndex: 'img',
                        customRender: text => {
                            return <img class="avatar" src={text} />
                        }
                    },
                    {
                        title: '主播老师',
                        dataIndex: 'teacher',
                        customRender: text => {
                            return <span>{text ? text.name : '—'}</span>
                        }
                    },
                    {title: '排序', dataIndex: 'order'},
                ]
            }
        },
        computed: {
            query() {
                return this.album ? `&filter[album_id]=${this.album.id}&sort=order,-id&expand=teacher,album` : ''
            }
        },
        watch: {
            '$store.getters.update'(val) {
                if(val && val.type == 'audio') {
                    this.$refs.dataList.getList();
                    this.$store.commit("changeUpdate", null);
                }
            }
        },
        methods: {
            addAudio() {
                let query = {};
                if(this.album) {
                    this.$store.commit("setForm", {album: this.album})
                    query = {album: this.album.id};
                }
                this.$router.push({name: 'AddEntity', params: {type: 'audio'}, query})
            },
            showDetail({item}) {
                goToDetailRoute(this, {item, type: 'audio'})
            },
            showEdit(item) {
                goToDetailRoute(this, {item, type: 'audio', edit: true})
            }
        }
    }
</script>

<style scoped lang="less">
</style>
