<template>
    <div class="activity-detail" v-if="detail">
        <div class="flex-box align-center justify-between">
            <div class="title-txt">{{detail.name}}</div>
            <a-button type="primary" @click="editDetail" v-show="tab == 1 || (tab == 2 && !formEdit)">编辑</a-button>
        </div>
        <a-tabs default-active-key="1" v-if="detail" @change="key => tab = key">
            <a-tab-pane key="1" tab="基础信息">
                <a-descriptions layout="vertical" :column="4" bordered v-if="detail">
                    <a-descriptions-item label="活动封面" :span="2">
                        <img :src="detail.img" class="avatar">
                    </a-descriptions-item>
                    <a-descriptions-item label="联系方式">{{detail.tel}}</a-descriptions-item>
                    <a-descriptions-item label="活动价格">{{detail.price == 0 ? '免费' : '收费'}}</a-descriptions-item>
                    <a-descriptions-item label="活动时间" :span="2">{{showTime(detail.start_time, detail.end_time)}}</a-descriptions-item>
                    <a-descriptions-item label="报名时间" :span="2">{{showTime(detail.enroll_start_time, detail.enroll_end_time)}}</a-descriptions-item>
                    <a-descriptions-item label="活动人数" :span="2">{{detail.people_count}}</a-descriptions-item>
                    <a-descriptions-item label="排序" :span="2">{{detail.order}}</a-descriptions-item>
                    <a-descriptions-item label="关联老师" :span="2">
                        <div class="flex-box align-center album-teacher" @click="showEntityDetail(detail.teacher, 'teacher')" v-if="detail.teacher">
                            <span style="margin-right: 12px">{{detail.teacher.name}}</span>
                            <img :src="detail.teacher.img" class="avatar mini" />
                        </div>
                        <span v-else>无</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="关联博物馆" :span="2">
                        <div class="flex-box align-center album-teacher" @click="showEntityDetail(detail.museum, 'museum')" v-if="detail.museum">
                            <span style="margin-right: 12px">{{detail.museum.name}}</span>
                            <img :src="detail.museum.img" class="avatar mini" />
                        </div>
                        <span v-else>无</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="活动详情" :span="2">
                        <div v-html="detail.info || '无'"></div>
                    </a-descriptions-item>
                    <a-descriptions-item label="活动须知" :span="2">
                        <div v-html="detail.notice || '无'"></div>
                    </a-descriptions-item>
                </a-descriptions>
            </a-tab-pane>
            <a-tab-pane key="2" tab="表单配置">
                <custom-form class="form-edit" :list="formControls" :style="{height: height + 'px'}" :edit.sync="formEdit" @save="handleFormSave"></custom-form>
            </a-tab-pane>
            <a-tab-pane key="3" tab="报名列表">
                <data-list
                        ref="enrollList"
                        :add-btn="null"
                        url="/enroll"
                        name="报名"
                        hide-edit
                        hide-delete
                        :deal-list="dealEnrollList"
                        :custom-url="getStatusQuery"
                        :height="height"
                        :query="query"
                        :columns="columns">
                    <template v-slot:title="{total}">
                        <span>共 {{total}} 条记录</span>
                    </template>
                    <template v-slot:action="{item}">
                        <a class="txt-btn" @click="showDetail(item)">查看</a>
                    </template>
                    <template v-slot:query="{form}">
                        <a-form-model-item label="">
                            <a-select
                                v-model="form.status"
                                :options="options"
                                :default-value="-1"
                                style="width: 180px"
                                placeholder="选择状态筛选"
                                @change="handleQueryChange"></a-select>
                        </a-form-model-item>
                    </template>
                </data-list>
                <a-modal v-model="visible" title="报名详情" :footer="null" @cancel="hideDetail">
                    <enroll-detail :enroll="enroll" class="enroll-info"></enroll-detail>
                </a-modal>
            </a-tab-pane>
            <a-tab-pane key="4" tab="排队列表">
                <data-list
                        ref="enrollList"
                        :add-btn="null"
                        url="/enroll"
                        name="排队"
                        hide-edit
                        hide-delete
                        :deal-list="dealEnrollList"
                        :custom-url="getQueueQuery"
                        :height="height"
                        :query="query"
                        :columns="columnsQueue">
                    <template v-slot:title="{total}">
                        <span>共 {{total}} 条记录</span>
                    </template>
                    <template v-slot:action="{item}">
                        <a class="txt-btn" @click="showDetail(item)">查看</a>
                    </template>
                    <template v-slot:query="{form}">
                        <a-form-model-item label="">
                            <a-select
                                v-model="form.status"
                                :options="options"
                                :default-value="-1"
                                style="width: 180px"
                                placeholder="选择状态筛选"
                                @change="handleQueryChange"></a-select>
                        </a-form-model-item>
                    </template>
                </data-list>
                <a-modal v-model="visible" title="排队详情" :footer="null" @cancel="hideDetail">
                    <enroll-detail :enroll="enroll" class="enroll-info"></enroll-detail>
                </a-modal>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
    import customForm from '../../components/custom-form';
    import enrollDetail from  "../../components/enroll-detail";
    import {goToDetailRoute, isEmpty} from "../../common/js/tool";
    import {getEnrollOrderStatus, getEnrollOrderStatusText} from "../../common/constant/activity";
    import moment from 'moment';
    export default {
        name: "ActivityDetail",
        components: {
            customForm,
            enrollDetail
        },
        props: {
            detail: Object
        },
        data() {
            return {
                height: null,
                tab: 1,
                formEdit: false,
                enrollList: null,
                enroll: null,
                visible: false,
                columns: [
                    {title: '报名时间', dataIndex: 'create_time'},
                    {
                        title: '用户昵称',
                        customRender: (text, record) => {
                            return <span>{record.user ? record.user.uname : ''}</span>
                        },
                    },
                    {
                        title: '头像',
                        width: 80,
                        customRender: (text, record) => {
                            return record.user ? <img class="avatar mini" src={record.user.avatar} /> : '';
                        }
                    },
                    {title: '姓名', dataIndex: 'name'},
                    {title: '手机号', dataIndex: 'tel'},
                    {title: '年龄', dataIndex: 'age', width: 80},
                    {title: '状态', dataIndex: 'statusText'}
                ],
                columnsQueue: [
                    {title: '排队时间', dataIndex: 'create_time'},
                    {
                        title: '用户昵称',
                        customRender: (text, record) => {
                            return <span>{record.user ? record.user.uname : ''}</span>
                        },
                    },
                    {
                        title: '头像',
                        width: 80,
                        customRender: (text, record) => {
                            return record.user ? <img class="avatar mini" src={record.user.avatar} /> : '';
                        }
                    },
                    {title: '姓名', dataIndex: 'name'},
                    {title: '手机号', dataIndex: 'tel'},
                    {title: '年龄', dataIndex: 'age', width: 80},
                    {title: '状态', dataIndex: 'statusText'}
                ]
            }
        },
        computed: {
            formControls() {
                let res = [];
                if(this.detail && this.detail.form_config) {
                    try {
                        res = JSON.parse(this.detail.form_config);
                    } catch (e) {
                        console.error(e);
                    }
                }
                return res;
            },
            query() {
                let detail = this.detail;
                return `&sort=-id&expand=user,order&filter[activity_id]=${detail.id}`
            },
            options() {
                let detail = this.detail;
                if(detail.price > 0) {
                    return [
                        {title: '全部', key: -1},
                        {title: '待支付', key: 1},
                        {title: '已支付', key: 2},
                        {title: '申请退款', key: 7},
                        {title: '退款中', key: 3},
                        {title: '已退款', key: 4},
                        {title: '退款失败', key: 5},
                        {title: '支付超时取消', key: 8},
                    ]
                } else {
                    return [
                        {title: '全部', key: -1},
                        {title: '已报名', key: 0},
                        {title: '已取消', key: 1},
                    ]
                }
            }
        },
        mounted() {
            // 计算内容区域高度
            this.height = document.querySelector("#entity_detail").clientHeight - 60 - 32 - 32;
        },
        methods: {
            getStatusQuery(url, form) {
                let res;
                let status = form.status;
                let price = this.detail.price;
                if(isEmpty(status) || status == -1) {
                    res = '';
                } else if(price > 0) {
                    if(status == 1) {
                        res = '&filter[is_del]=0&status=1';
                    } else if(status == 2) {
                        res = '&filter[is_del]=0&status=2';
                    } else if(status == 7) {
                        res = '&filter[is_del]=1&status=2';
                    } else if(status == 8) {
                        res = '&filter[is_del]=2';
                    } else {
                        res = `&filter[is_del]=1&status=${status}`;
                    }
                } else {
                    res = `&filter[is_del]=${status}`;
                }
                return res;
            },
            getQueueQuery(url, form){
                let res;
                let status = form.status;
                let price = this.detail.price;
                if(isEmpty(status) || status == -1) {
                    res=`&filter[is_del]=0&filter[in_queue]=1`;
                } else if(price > 0) {
                    if(status == 1) {
                        res = '&filter[is_del]=0&filter[in_queue]=0&status=1';
                    } else if(status == 2) {
                        res = '&filter[is_del]=0&filter[in_queue]=1&status=2';
                    } else if(status == 8) {
                        res = '&filter[is_del]=4&filter[in_queue]=0';
                    } else {
                        res = `&filter[is_del]=3&filter[in_queue]=0&status=${status}`;
                    }
                } else {
                    res = `&filter[is_del]=${status}&filter[in_queue]=1`;
                }
                return res;

            },
            handleQueryChange() {
                this.$refs.enrollList.getList();
            },
            dealEnrollList(list, fn) {
                let price = this.detail.price;
                list.forEach(item => {
                    item.status = getEnrollOrderStatus(price, item);
                    item.statusText = getEnrollOrderStatusText(price, item);
                });
                fn(list);
            },
            hideDetail() {
                this.enroll = null;
                this.visible = false;
            },
            showDetail(enroll) {
                enroll.activity = this.detail;
                this.enroll = enroll;
                this.visible = true;
            },
            handleFormSave(config) {
                let id = this.detail.id;
                this.$axios({
                    url: "/activity/" + id,
                    method: 'PATCH',
                    data: {
                        form_config: config
                    }
                }).then(res => {
                    this.$set(this.detail, 'form_config', res.form_config);
                    this.formEdit = false;
                })
            },
            showTime(start, end) {
                return start && end ? `${moment(start).format('YYYY-MM-DD HH:mm')}至${moment(end).format('YYYY-MM-DD HH:mm')}` : "—"
            },
            editDetail() {
                if(this.tab == 1) {
                    this.$emit("edit")
                } else {
                    this.formEdit = true;
                }
            },
            showEntityDetail(item, type) {
                goToDetailRoute(this, {item, type});
            }

        }
    }
</script>

<style scoped lang="less">
    .title-txt {
        font-weight: bold;
        font-size: 16px;
        color: @heading-color;
        line-height: 32px;
    }
    .album-teacher {
        padding: @padding-xs;
        cursor: pointer;
        &:hover {
            background-color: @background-color-base;
        }
    }
</style>
