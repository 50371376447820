<template>
    <div class="museum-detail" v-if="detail">
        <div class="flex-box align-center justify-between">
            <div class="title-txt">{{detail.name}}</div>
            <a-button type="primary" @click="editDetail" v-show="tab == 1">编辑</a-button>
        </div>
        <a-tabs default-active-key="1" v-if="detail" @change="key => tab = key">
            <a-tab-pane key="1" tab="基础信息">
                <a-descriptions layout="vertical" :column="4" bordered v-if="detail">
                    <a-descriptions-item label="地址">{{detail.location}}</a-descriptions-item>
                    <a-descriptions-item label="价格">{{detail.price / 100 }}元</a-descriptions-item>
                    <a-descriptions-item label="展馆">{{detail.museum ? detail.museum.name : '未知' }}</a-descriptions-item>
                    <a-descriptions-item label="展厅">{{detail.exhibitionHouse ? detail.exhibitionHouse.name : '未知' }}</a-descriptions-item>
                    <a-descriptions-item label="所在地区">{{`${detail.province} ${detail.city} ${detail.district}`}}</a-descriptions-item>
                    <a-descriptions-item label="点赞数">{{detail.likeCount}}</a-descriptions-item>
                    <a-descriptions-item label="收藏数">{{detail.wantCount}}</a-descriptions-item>
                    <a-descriptions-item label="排序">{{detail.order}}</a-descriptions-item>
                    <a-descriptions-item label="展览时间" :span="2">{{showTime(detail.start_time, detail.end_time)}}</a-descriptions-item>
                    <a-descriptions-item label="图片" :span="2">
                        <img :src="detail.img" class="avatar">
                    </a-descriptions-item>
                    <a-descriptions-item label="详情" :span="4">
                        <div v-html="detail.detail || '无'"></div>
                    </a-descriptions-item>
                </a-descriptions>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>

    import moment from "moment";

    export default {
        name: "ExhibitionDetail",
        props: {
            detail: Object
        },
        data() {
            return {
                tab: 1,
                height: null,
            }
        },
        computed: {
            query() {
                let detail = this.detail;
                return detail ? `&filter[museum_id]=${detail.id}` : '';
            }
        },
        mounted() {
            // 计算内容区域高度
            this.height = document.querySelector("#entity_detail").clientHeight - 60 - 32 - 32;
        },
        methods: {
            showTime(start, end) {
                return start && end ? `${moment(start).format('YYYY-MM-DD HH:mm')}至${moment(end).format('YYYY-MM-DD HH:mm')}` : "—"
            },
            editDetail() {
                if(this.tab == 1) {
                    this.$emit("edit")
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .title-txt {
        font-weight: bold;
        font-size: 16px;
        color: @heading-color;
        line-height: 32px;
    }
    .detail-box {
        position: relative;
    }
    .add-form {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
</style>
