<template>
    <div class="museum-detail" v-if="detail">
        <div class="flex-box align-center justify-between">
            <div class="title-txt">{{detail.name}}</div>
            <a-button type="primary" @click="editDetail" v-show="tab == 1">编辑</a-button>
        </div>
        <a-tabs default-active-key="1" v-if="detail" @change="key => tab = key">
            <a-tab-pane key="1" tab="基础信息">
                <a-descriptions layout="vertical" bordered v-if="detail">
                    <a-descriptions-item label="开放时间">{{detail.open_time}}</a-descriptions-item>
                    <a-descriptions-item label="位置信息">{{detail.location}}</a-descriptions-item>
                    <a-descriptions-item label="位置坐标">{{detail.point}}</a-descriptions-item>
                    <a-descriptions-item label="所在区域">{{`${detail.province} ${detail.city} ${detail.district}`}}</a-descriptions-item>
                    <a-descriptions-item label="博物馆封面" :span="2">
                        <img :src="detail.img" class="avatar">
                    </a-descriptions-item>
                    <a-descriptions-item label="详细介绍" :span="3">
                        <div v-html="detail.detail || '无'"></div>
                    </a-descriptions-item>
                </a-descriptions>
            </a-tab-pane>
            <a-tab-pane key="2" tab="展厅管理">
                <data-list
                        ref="roomList"
                        class="content"
                        add-btn="添加展厅"
                        url="/museum-house"
                        name="展厅"
                        :before-update="beforeAddRoom"
                        :height="height"
                        :rules="rules"
                        :query="query"
                        :columns="columns">
                    <template #title>
                        <span>展厅列表</span>
                    </template>
                    <template v-slot:query="{form}">
                        <a-form-model-item label="">
                            <a-input v-model="form.name" placeholder="输入展厅名称查询"></a-input>
                        </a-form-model-item>
                    </template>
                    <template v-slot:edit="{form}">
                        <a-form-model-item :label="item.label" :prop="item.key" v-for="item in items" :key="item.key">
                            <component
                                    :is="item.component"
                                    :style="item.style"
                                    v-model="form[item.key]"
                                    v-bind="item.props">
                            </component>
                        </a-form-model-item>
                    </template>
                </data-list>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>

    export default {
        name: "MuseumDetail",
        props: {
            detail: Object
        },
        data() {
            return {
                tab: 1,
                height: null,
                columns: [
                    {title: '展厅名称', dataIndex: 'name'},
                    {
                        title: '展厅图片',
                        dataIndex: 'img',
                        customRender: text => {
                            return text ? <img class="avatar" src={text} /> : '无'
                        }
                    },
                    {title: '展厅介绍', dataIndex: 'info'},
                ],
                rules: {
                    name: [{ required: true, message: '请输入展厅名称', trigger: 'blur' }]
                },
                items: [
                    {
                        key: 'name',
                        label: '展厅名称',
                        component: 'a-input',
                        props: {
                            placeholder: '请输入展厅名称',
                        }
                    },
                    {
                        key: 'img',
                        label: '展厅图片',
                        component: 'form-upload'
                    },
                    {
                        key: 'info',
                        label: '展厅介绍',
                        component: 'a-input',
                        props: {
                            placeholder: '请输入展厅介绍',
                        },
                    },
                ]
            }
        },
        computed: {
            query() {
                let detail = this.detail;
                return detail ? `&filter[museum_id]=${detail.id}` : '';
            }
        },
        mounted() {
            // 计算内容区域高度
            this.height = document.querySelector("#entity_detail").clientHeight - 60 - 32 - 32;
        },
        methods: {
            beforeAddRoom(form, fn) {
                if(!form.id) {
                    form.museum_id = this.detail.id;
                }
                fn(form);
            },
            editDetail() {
                if(this.tab == 1) {
                    this.$emit("edit")
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .title-txt {
        font-weight: bold;
        font-size: 16px;
        color: @heading-color;
        line-height: 32px;
    }
    .detail-box {
        position: relative;
    }
    .add-form {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
</style>
