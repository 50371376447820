<template>
    <div class="enroll-detail" v-if="enroll">
        <div class="enroll-detail-title">{{enroll.statusText}}</div>
        <div class="enroll-detail-ctx">
            <div class="enroll-detail-item" v-for="item in info" :key="item.key">
                <div class="enroll-detail-item-label">{{item.label}}</div>
                <div class="enroll-detail-item-value">{{item.value}}</div>
            </div>
            <div class="enroll-detail-item" v-if="orderDetail.length > 0">
                <div class="enroll-detail-item-label">支付详情</div>
                <div class="enroll-detail-item-value">
                    <a-timeline class="enroll-order-line">
                        <a-timeline-item v-for="(item, index) in orderDetail" :key="index">
                            <p>{{item.value}}<br/>{{item.time}}</p>
                        </a-timeline-item>
                    </a-timeline>
                </div>
            </div>
        </div>
        <div class="enroll-detail-btn" v-if="enroll.status == 8">
            <a-popconfirm placement="top" ok-text="确认" cancel-text="取消" @visibleChange="handleChange" @confirm="refund">
                <template slot="title">
                    <p>请确认退款金额</p>
                    <div>
                        ￥
                        <a-input-number v-model="num"/>
                    </div>
                </template>
                <a-button type="primary">确认退款</a-button>
            </a-popconfirm>
        </div>
    </div>
</template>

<script>
    export default {
        name: "enrol-detail",
        props: {
            enroll: Object
        },
        data() {
            return {
                num: null
            }
        },
        computed: {
            info() {
                let enroll = this.enroll;
                let res = [];
                if(enroll && enroll.form_detail) {
                    try {
                        let conf = JSON.parse(enroll.form_config);
                        let detail = JSON.parse(enroll.form_detail);
                        res = conf.map(item => {
                            return {
                                label: item.label,
                                key: item.key,
                                value: detail[item.key]
                            }
                        })
                    } catch (e) {
                        console.error(e)
                    }
                }
                return res;
            },
            orderDetail() {
                let res = [];
                let enroll = this.enroll;
                let price = enroll.activity.price;
                let showPrice = `￥${price / 100}`;
                if(price > 0) {
                    if(enroll.status == 3) {
                        res.push({
                            value: `待支付${showPrice}`,
                            time: enroll.create_time
                        })
                    } else if(enroll.status == 4) {
                        res.push({
                            value: `已支付${showPrice}`,
                            time: enroll.order.create_time
                        })
                    } else if(enroll.status == 5) {
                        res = [
                            {
                                value: `已支付${showPrice}`,
                                time: enroll.order.create_time
                            },
                            {
                                value: '申请退款',
                                time: enroll.update_time
                            },
                            {
                                value: '开始退款',
                                time: enroll.order.refund_start_time
                            },
                        ]
                    } else if(enroll.status == 6) {
                        res = [
                            {
                                value: `已支付${showPrice}`,
                                time: enroll.order.create_time
                            },
                            {
                                value: '申请退款',
                                time: enroll.update_time
                            },
                            {
                                value: '开始退款',
                                time: enroll.order.refund_start_time
                            },
                            {
                                value: '退款成功',
                                time: enroll.order.refund_end_time
                            },
                        ]
                    } else if(enroll.status == 7) {
                        res = [
                            {
                                value: `已支付${showPrice}`,
                                time: enroll.order.create_time
                            },
                            {
                                value: `申请退款`,
                                time: enroll.update_time
                            },
                            {
                                value: '开始退款',
                                time: enroll.order.refund_start_time
                            },
                            {
                                value: '退款失败'
                            },
                        ]
                    } else if(enroll.status == 8) {
                        res = [
                            {
                                value: `已支付${showPrice}`,
                                time: enroll.order.create_time
                            },
                            {
                                value: '申请退款',
                                time: enroll.update_time
                            },
                        ]
                    }
                }
                return res;
            }
        },
        methods: {
            test() {
                this.$set(this.enroll, 'status', 7);
            },
            handleChange(visible) {
                if(visible) {
                    this.num = this.enroll.activity.price / 100;
                }
            },
            refund() {
                let num = this.num * 100;
                if(num) {
                    let order = this.enroll.order;
                    if(num > 0 && num <= order.price) {
                        // 先将状order态置为3 退款中
                        this.$axios({
                            url: `/order/${order.id}`,
                            method: 'PATCH',
                            data: {
                                status: 3
                            }
                        }).then(res => {
                            this.$axios({
                                url: "/order/refund",
                                method: 'POST',
                                data: {
                                    no: order.no,
                                    price: order.price,
                                    refundFee: num
                                }
                            }).then(() => {
                                this.$message.success("已发起退款申请");
                                this.$set(this.enroll, 'status', 5);
                                this.$set(this.enroll, 'statusText', '退款中');
                                this.$set(this.enroll.order, 'refund_start_time', res.refund_start_time);
                            }).catch(() => {
                                // 退款接口异常将order status置回 2
                                this.$axios({
                                    url: `/order/${order.id}`,
                                    method: 'PATCH',
                                    data: {
                                        status: 2
                                    }
                                });
                            })
                        })
                    } else if(num <= 0) {
                        this.$message.warning("退款金额必须大于0");
                    } else if(num > order.price) {
                        this.$message.warning("退款金额不能大于支付金额");
                    }
                } else {
                    this.$message.warning("请输入退款金额");
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .enroll-detail-title {
        font-size: 16px;
        font-weight: bold;
    }
    .enroll-detail-ctx {
        margin-top: 12px;
    }
    .enroll-detail-item {
        display: flex;
        margin-top: 6px;
    }
    .enroll-detail-item-label {
        min-width: 60px;
    }
    .enroll-detail-item-value {
        margin-left: 10px;
    }
    .enroll-order-line {
        margin-top: 6px;
    }
    .enroll-detail-btn {
        text-align: center;
    }
</style>
