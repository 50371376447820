import { render, staticRenderFns } from "./MuseumDetail.vue?vue&type=template&id=d80c28d0&scoped=true&"
import script from "./MuseumDetail.vue?vue&type=script&lang=js&"
export * from "./MuseumDetail.vue?vue&type=script&lang=js&"
import style0 from "./MuseumDetail.vue?vue&type=style&index=0&id=d80c28d0&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d80c28d0",
  null
  
)

export default component.exports