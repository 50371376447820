<template>
    <div class="custom-form">
        <div class="form-conf">
            <div class="preview-area">
                <drag-zone :list="form" class="form-preview">
                    <template v-slot="{item: control, index}">
                        <div class="form-item" :class="{select: item && item.key == control.key, error: control.error, edit}" @click="selectFormItem(control)">
                            <div class="form-label">{{control.label}}<span v-if="control.must"> *</span></div>
                            <div class="form-opt-show"  v-if="control.options && (control.component == 'radio' || control.component == 'checkbox')">
                                <div class="opt-show-item" :class="{radio: control.component == 'radio'}" v-for="(opt, i) in control.options" :key="i">
                                    <div class="opt-show-icon"></div>
                                    <div class="opt-show-title">{{opt}}</div>
                                </div>
                            </div>
                            <div class="form-input" :class="{textarea: control.component == 'input' && control.type == 'textarea'}" v-else>
                                <div class="input-place">{{control.placeholder}}</div>
                                <a-icon class="icon-arrow-right" type="right" v-if="control.component == 'select'" />
                            </div>
                            <a-icon class="form-item-remove" type="close" @click.stop="removeItem(control, index)" />
                        </div>
                    </template>
                </drag-zone>
                <a-space class="add-item" @click.stop="showItems" v-show="edit">
                    <a-icon type="plus"/>
                    <span>添加控件</span>
                </a-space>
                <form-controls ref="controls" class="form-temp" @select="itemAdd"></form-controls>
            </div>
            <div class="item-setting">
                <h3 class="setting-title">控件设置</h3>
                <div class="form-area" v-if="item">
                    <a-form-model
                            class="setting-form"
                            ref="setForm"
                            label-align="left"
                            :model="item"
                            :rules="setRules"
                            :label-col="{span: 6}"
                            :wrapper-col="{span: 18}">
                        <a-form-model-item  label="控件名称" prop="label">
                            <a-input v-model="item.label" placeholder="请输入控件名称"></a-input>
                        </a-form-model-item>
                        <a-form-model-item  label="未输入占位" v-if="item.component == 'input' || item.component == 'select'">
                            <a-input v-model="item.placeholder" placeholder="请输入用户未输入时显示内容"></a-input>
                        </a-form-model-item >
                        <a-form-model-item  label="选项列表" v-if="item.component == 'select' || item.component == 'radio' || item.component == 'checkbox'">
                            <div class="form-set-options">
                                <div class="set-option" v-for="(o, j) in item.options" :key="item.key + j">
                                    <a-input v-model="item.options[j]" placeholder="请输入选项内容"></a-input>
                                    <div class="set-opt-edit">
                                        <a-icon type="minus" class="icon-minus" @click="removeOption(j)" />
                                        <a-icon type="plus" class="icon-plus" @click="addOption(j)" />
                                    </div>
                                </div>
                            </div>
                        </a-form-model-item >
                        <a-form-model-item  label="输入校验">
                            <div class="form-set-rules" v-if="item.rules && item.rules.length > 0">
                                <div class="set-rule" v-for="(o, j) in item.rules" :key="item.key + j">
                                    <div class="rule-name">规则{{j + 1}}：{{o.title}}</div>
                                    <div class="rule-len" v-if="o.type == 3">
                                        <div class="len-title">区间</div>
                                        <a-input class="len-input" v-model="o.rule.min"></a-input>
                                        <div class="len-line"></div>
                                        <a-input class="len-input" v-model="o.rule.max"></a-input>
                                    </div>
                                    <div class="rule-len" v-else-if=" o.type == 4">
                                        <div class="len-title">区间</div>
                                        <a-input class="len-input" v-model="o.rule.small"></a-input>
                                        <div class="len-line"></div>
                                        <a-input class="len-input" v-model="o.rule.big"></a-input>
                                    </div>
                                    <a-input v-model="o.rule.regex" placeholder="校验正则表达式" style="margin-bottom: 6px" v-if="o.type == 6"></a-input>
                                    <a-input v-model="o.rule.message" placeholder="请输入校验不通过提示信息"></a-input>
                                    <a-icon class="icon-close" type="close" @click.stop="removeRule(j)"/>
                                </div>
                            </div>
                            <a-button type="primary" @click="addRule">添加规则</a-button>
                        </a-form-model-item >
                    </a-form-model>
                </div>
            </div>
        </div>
        <a-space class="form-btn" v-show="edit">
            <a-button @click="cancel">取 消</a-button>
            <a-button type="primary" @click="saveForm">保 存</a-button>
        </a-space>
        <a-modal
                title="选择校验类型"
                centered
                :width="300"
                @ok="confirmRule"
                v-model="ds">
            <a-select v-model="rule" placeholder="请选择校验类型" style="width: 100%">
                <a-select-option :key="i" :title="opt.title" :value="i" v-for="(opt, i) in ruleOptions" >{{opt.title}}</a-select-option>
            </a-select>
        </a-modal>
    </div>
</template>

<script>
    import dragZone from './drag-zone'
    import formControls from './form-controls';
    import {rules, base} from "../common/constant/formItems";
    import {clone, randomString, isEmpty, isArray} from "../common/js/tool";
    import Scroller from "../common/js/scroll";

    export default {
        name: "custom-form",
        components: {
            dragZone,
            formControls
        },
        props: {
            list: Array,
            edit: Boolean
        },
        data() {
            return {
                item: null, //当前选择的表单
                form: [], //表单列表
                setRules: {
                    label: [{required: true, message: '请输入控件名称', trigger: 'blur'}]
                },
                ruleOptions: rules,
                rule: 0,
                ds: false,
            }
        },
        watch: {
            list() {
                this.setForm();
            },
            edit(val) {
                if(val && this.form.length <= 0) {
                    this.form = clone(base);
                }
            }
        },
        created() {
            this.setForm();
        },
        mounted() {
            let dom = this.$el.querySelector(".form-preview");
            dom && (this.scroller = new Scroller(dom));
        },
        methods: {
            setForm() {
                let list = this.list;
                //不传list 传入不是数组 或者传入数组长度为0 视为一个新的表单
                if(list && isArray(list) && list.length > 0) {
                    this.form = clone(this.list);
                } else {
                    this.form = [];
                }
            },
            saveForm() {
                this.checkCurrent(() => {
                    let form = this.form;
                    let flag = true;
                    for(let i = 0, l = form.length; i < l; i++) {
                        let item = form[i];
                        if(item.error) {
                            flag = false;
                            this.item = item;
                            this.$nextTick(() => {
                                this.checkCurrent();
                                this.scrollControl(i);
                            });
                            break;
                        }
                    }
                    if(flag) {
                        this.item = null;
                        this.$emit("save", JSON.stringify(form));
                    } else {
                        this.$message.warning("请正确配置表单项");
                    }
                })
            },
            cancel() {
                this.item = null;
                this.setForm();
                this.$emit("update:edit", false);
            },
            confirmRule() {
                let rule = this.rule, item = this.item;
                if(isEmpty(rule)) {
                    this.$message.warning("请选择校验类型")
                } else {
                    rule = clone(this.ruleOptions[rule]);
                }
                let rules = item.rules;
                if(rules) {
                    rules.push(rule);
                } else {
                    rules = [rule];
                }
                this.$set(item, "rules", rules);
                this.$set(item, "must", this.getMust(rules));
                this.hideDialog();
            },
            hideDialog() {
                this.ds = false;
            },
            removeRule(i) {
                let item = this.item;
                let rules = item.rules;
                rules.splice(i, 1);
                this.$set(item, "rules", rules);
                this.$set(item, "must", this.getMust(rules));
            },
            addRule() {
                this.ds = true;
            },
            getMust(rules) {
                let must = false;
                if(rules && rules.length > 0) {
                    must = rules.findIndex(r => r.rule.required) >= 0;
                }
                return must;
            },
            removeOption(i) {
                let item = this.item;
                let options = item.options;
                if(options.length <= 1) return;
                options.splice(i, 1);
                this.$set(item, "options", options);
            },
            addOption(i) {
                let item = this.item;
                let options = item.options;
                options.splice(i + 1, 0, null);
                this.$set(item, "options", options);
            },
            checkCurrent(fn) {
                if(this.$refs.setForm) {
                    this.$refs.setForm.validate(flag => {
                        this.$set(this.item, 'error', !flag);
                        typeof fn === "function" && fn(flag);
                    });
                } else {
                    fn(true);
                }
            },
            selectFormItem(item, fn) {
                if(!this.edit) return;
                //检查当前表单项配置
                this.checkCurrent(() => {
                    //点击当前选中的表单项取消选中状态
                    if(this.item && this.item.key == item.key) {
                        this.item = null;
                    } else {
                        this.item = item;
                        this.$nextTick(() => {
                            item.error && this.checkCurrent();
                            typeof fn === "function" && fn()
                         });
                    }
                });
            },
            removeItem(control, index) {
                this.form.splice(index, 1);
                //删除被选中的 清空选中
                if(this.item && this.item.key == control.key) {
                    this.item = null;
                }
            },
            showItems() {
                this.$refs.controls.showControls();
            },
            itemAdd(c) {
                let conf = clone(c.config);
                let str = randomString(8);
                let key = `${conf.component}_${str}`;
                if(this.form.some(f => f.key == key)) {
                    str = randomString(8);
                    key = `${conf.component}_${str}`;
                }
                conf.key = key;
                this.form.push(conf);
                this.selectFormItem(conf, () => {
                    this.scrollControl(this.form.length - 1);
                });
            },
            scrollControl(index) {
                let item = document.querySelectorAll(".form-item")[index].parentNode;
                this.scroller.scrollTo(item);
            },
        }
    }
</script>

<style scoped lang="less">
    .custom-form {
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 1200px;
        background-color: @component-background;
    }
    .form-conf {
        flex: 1;
        display: flex;
        overflow: hidden;
    }
    .preview-area {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 377px;
        border: var(--border);
    }
    .form-preview {
        flex: 1;
        background-color: @background-color-base;
        overflow: auto;
    }
    .add-item {
        flex-shrink: 0;
        justify-content: center;
        height: 48px;
        border-top: var(--border);
        cursor: pointer;
        font-size: 14px;
        color: @primary-color;
        &:hover {
            background-color: @background-color-light;
        }
        .icon-plus {
            margin-right: 6px;
            width: 14px;
            height: 14px;
            line-height: 14px;
        }
    }
    .form-temp {
        position: absolute;
        bottom: 0;
        left: -12px;
        z-index: 3;
    }
    .form-item {
        position: relative;
        padding: 10px;
        border-bottom: var(--border);
        background-color: @component-background;
        &::after {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px dashed @primary-color;
        }
        &.edit {
            cursor: move;
            &:hover {
                &::after {
                    display: block;
                }
                .form-item-remove {
                    display: block;
                }
            }
        }

        &.error {
            &::after {
                display: block;
                border: 1px solid @error-color;
            }
        }

        &.select {
            &::after {
                display: block;
                border: 1px solid @primary-color;
            }
            .form-item-remove {
                display: block;
            }
        }
        .base-tip {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            z-index: 1;
        }
        .el-icon-info {
            font-size: 16px;
            line-height: 1;
            color: @primary-color;
        }
    }
    .form-input {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 0 5px 0 10px;
        height: 32px;
        border: var(--border);
        color: @text-color;
        .icon-arrow-right {
            font-size: 16px;
            color: @text-color-secondary;
        }
        &.textarea {
            align-items: normal;
            padding-top: 10px;
            height: 100px;
        }
    }
    .input-place {
        flex: 1;
    }
    .form-label {
        height: 14px;
        span {
            color: @error-color;
        }
    }
    .form-opt-show {
        padding-top: 6px;
    }
    .opt-show-item {
        display: flex;
        align-items: center;
        margin-top: 6px;
        &.radio .opt-show-icon {
            border-radius: 100%;
        }
        .opt-show-icon {
            width: 16px;
            height: 16px;
            border: var(--border);
        }
        .opt-show-title {
            margin-left: 10px;
            font-size: 14px;
        }
    }
    .form-item-remove {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 18px;
        height: 18px;
        background-color: @primary-color;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: @component-background;
        cursor: pointer;
        z-index: 2;
    }
    .item-setting {
        margin-left: 40px;
        width: 360px;
    }
    .form-area {
        margin-top: 20px;
    }
    .set-option {
        display: flex;
        &:not(:first-child) {
            margin-top: 6px;
        }
    }
    .set-opt-edit {
        display: flex;
        align-items: center;
        margin-left: 10px;
        height: 30px;
        .icon-minus,
        .icon-plus {
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background-color: @text-color-secondary;
            cursor: pointer;
            color: @component-background;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
        }
        .icon-plus {
            margin-left: 6px;
        }
    }
    .form-set-rules {
        margin: -6px 0 20px;
    }
    .set-rule {
        position: relative;
        padding: 6px 10px;
        background-color: @background-color-light;
        &:not(:first-of-type) {
            margin-top: 6px;
        }
        &:hover .icon-close {
            display: block;
        }
        .icon-close {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            width: 16px;
            height: 16px;
            background-color: @error-color;
            cursor: pointer;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: @text-color-inverse;
        }
    }
    .rule-name {
        margin-bottom: 6px;
    }
    .rule-len {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
    }
    .len-title {
        flex-shrink: 0;
        width: 48px;
    }
    .len-line {
        margin: 0 6px;
        width: 30px;
        height: 1px;
        background-color: @text-color-secondary;
    }
    .form-btn {
        margin-top: 16px;
        padding-top: 16px;
        border-top: var(--border);
        justify-content: center;
    }
    .hint-title{
        margin:20px 0;
    }
</style>
