<template>
    <div class="content md detail-box" id="entity_detail">
        <form-area
            ref="detailForm"
            class="flex-box vertical add-form"
            layout="horizontal"
            :label-col="{span: 4}"
            :wrapper-col="{span: 20}"
            :items="conf.items"
            :form="form"
            @cancel="cancel()"
            @confirm="confirm" v-show="isAdd || edit"></form-area>
        <component :is="conf.component" :detail="detail" @edit="editDetail" v-show="!isAdd && !edit" v-if="conf.component"></component>
        <a-descriptions layout="vertical" bordered v-show="!isAdd && !edit" v-else-if="detail">
            <template #title>
                <div class="flex-box align-center justify-between">
                    <div class="title-txt">{{detail.name}}</div>
                    <a-button type="primary" @click="editDetail">编辑</a-button>
                </div>
            </template>
            <a-descriptions-item :label="item.label" :span="item.span || 1" v-for="item in renderItems" :key="item.key">
                <vnodes :vnodes="item.slot(detail)" v-if="item.slot"/>
                <span v-else>{{detail[item.key]}}</span>
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
    import AlbumDetail from "./album/AlbumDetail";
    import ActivityDetail from "./activity/ActivityDetail";
    import MuseumDetail from "./museum/MuseumDetail";
    import ExhibitionDetail from "./exhibition/ExhibitionDetail";
    import entityConfig from "../common/constant/entity";
    import {clone} from "../common/js/tool";

    export default {
        name: "EntityDetail",
        components: {
            AlbumDetail,
            MuseumDetail,
            ActivityDetail,
            ExhibitionDetail
        },
        data() {
            return {
                edit: false,
                detail: null,
                form: {}
            }
        },
        computed: {
            id() {
                return this.$route.params.id;
            },
            type() {
                return this.$route.params.type
            },
            conf() {
                let type = this.type;
                return entityConfig[type];
            },
            //是新增还是详情
            isAdd() {
                return this.$route.name == 'AddEntity'
            },
            renderItems() {
                let conf = this.conf;
                if(conf) {
                    let items = conf.renderItems(this);
                    return items
                } else {
                    return [];
                }
            },
            detailForm() {
                return this.$refs.detailForm;
            }
        },
        watch: {
            $route() {
                this.detail = null;
                this.init();
            },
            conf(val) {
                if(val) {
                    document.title = `${val.name}详情`
                }
            }
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                let conf = this.conf;
                this.form = conf && conf.init ? conf.init : {};
                if(!this.isAdd) {
                    this.getEntity();
                } else {
                    //添加实体通过query参数查看有无默认关联实体
                    let query = this.$route.query;
                    let keys = Object.keys(query);
                    if(keys.length > 0) {
                        // 如果有关联实体 则先从store中取出关联的实体对象 如果不存在（刷新了页面）则从服务端获取
                        let form = this.$store.getters.form;
                        keys.forEach(key => {
                            if(form && form[key]) {
                                this.$set(this.form, key, form[key]);
                            } else {
                                this.$axios(`/${key}/${query[key]}`).then(res => {
                                    this.$set(this.form, key, res);
                                });
                            }
                        })
                    }
                }
            },
            setEditStatus() {
                if(this.$route.query.edit) {
                    this.editDetail()
                } else {
                    this.cancelEdit();
                }
            },
            editDetail() {
                let conf = this.conf;
                if(conf && typeof conf.beforeEdit === 'function') {
                    conf.beforeEdit.call(this, this.detail).then(this.showEdit);
                } else {
                    this.showEdit();
                }
            },
            showEdit() {
                this.form = clone(this.detail);
                this.edit = true;
            },
            cancelEdit(){
                this.form = {};
                this.edit = false;
            },
            getEntity() {
                let detail = this.$store.getters.detail;
                let id = this.$route.params.id;
                if(detail && detail.type == this.type && detail.obj.id == id) {
                    this.dealInfo(detail.obj);
                } else {
                    this.getDetail();
                }
            },
            getDetail() {
                let id = this.$route.params.id;
                if(!id) return;
                let conf = this.conf;
                let url = `${conf.url}/${id}${conf.expand ? conf.expand : ''}`;
                this.$axios(url).then(res => {
                    this.dealInfo(res)
                });
            },
            dealInfo(info) {
                let conf = this.conf;
                if(conf && typeof conf.dealDetail === 'function') {
                    conf.dealDetail.call(this, info).then(res => {
                        this.detail = res || info;
                        this.setEditStatus();
                    })
                } else {
                    this.detail = info;
                    this.setEditStatus();
                }
            },
            confirm(form) {
                let conf = this.conf;
                if(conf && typeof conf.beforeUpdate === 'function') {
                    conf.beforeUpdate(form).then(res => {
                        this.updateDetail(res);
                    })
                } else {
                    this.updateDetail(form);
                }
            },
            updateDetail(form) {
                let url = this.conf.url;
                let method = 'POST';
                if(form.id) {
                    url += '/' + form.id;
                    method = 'PATCH';
                    delete form.create_time;
                    delete form.update_time;
                }
                this.$axios({
                    url,
                    method,
                    data: form
                }).then(() => {
                    this.$message.success("保存成功");
                    this.$store.commit("changeUpdate", {type: this.type})
                    this.getDetail();
                    this.cancel();
                })
            },
            cancel() {
                this.cancelEdit();
                if(this.$route.query.edit || this.isAdd) {
                    this.$router.back();
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .detail-box {
        position: relative;
        overflow: auto;
    }
    .add-form {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
</style>
