<template>
    <div class="drag-zone" @drop.stop="handleDrop" @dragover.stop="handleDragOver">
        <transition-group name="vertical" tag="ul">
            <li class="drag-item" :class="[itemClass]"
                 draggable
                 @dragstart="handleDragStart(i)"
                 @drop.stop="handleElementDrop($event, i)"
                 @dragover.stop="handleDragOver"
                 v-for="(c, i) in list" :key="c.key">
                <slot v-bind:item="c" v-bind:index="i"></slot>
            </li>
        </transition-group>
    </div>
</template>

<script>
    import {isEmpty} from "../common/js/tool";
    export default {
        name: "drag-zone",
        props: {
            list: Array,
            itemClass: String
        },
        data() {
            return {
                di: null
            }
        },
        methods: {
            handleDragOver(e) {
                e.preventDefault();
                /* console.log(e.target) */
            },
            handleDragStart(i) {
                this.di = i;
            },
            handleElementDrop(e, i) {
                let index = e.dataTransfer.getData("text");
                //console.log(index,isEmpty(index))
                // 从组件列表里拖拽过来的元素
                if(isEmpty(index)) {
                    let di = this.di, list = this.list;
                    let dragItem = list[di];//被拖拽元素
                    //将被拖拽元素从原有位置移除
                    list.splice(di, 1);
                    //将被拖拽元素插入目标上方
                    list.splice(i, 0, dragItem);
                    this.$emit("sort", list);
                } else {
                    // 从左边模板拖拽过来的元素
                    this.$emit("outside", {text: index, index: i});
                }
            },
            handleDrop(e) {
                let index = e.dataTransfer.getData("text");
                // 组件列表元素拖拽到底部
                if(isEmpty(index)) {
                    let di = this.di, list = this.list;
                    let dragItem = list[di];//被拖拽元素
                    //将被拖拽元素从原有位置移除
                    list.splice(di, 1);
                    //将被拖拽元素插入列表最底部
                    list.push(dragItem);
                    this.$emit("sort", list);
                } else {
                    // 从左边模板拖拽过来的元素
                    this.$emit("outside", {text: index})
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .drag-item {
        width: 100%;
        transition: all .5s;
        overflow: hidden;
    }

    .vertical-enter {
        opacity: 0;
        transform: translateY(-100%);
    }
    .vertical-leave-to
        /* .list-complete-leave-active for below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(100%);
    }
    .vertical-leave-active {
        position: absolute;
    }

    .horizontal-enter {
        opacity: 0;
        transform: translateX(-100%);
    }
    .horizontal-leave-to
        /* .list-complete-leave-active for below version 2.1.8 */ {
        opacity: 0;
        transform: translateX(100%);
    }
    .horizontal-leave-active {
        position: absolute;
    }
</style>
